import React from "react";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
// import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import Layout from "../components/layout";
import SEO from "../components/seo";

const LunarExplorer = ({ location }) => {
  function transform(node, index) {
    console.log(node, index)
  }
    return (
      <StaticQuery
        query={graphql`
        query {
          wpgraphql {
            page(id: "/lunar-explorer/", idType: URI) {
              content
              title
              slug
              uri
            }
          }
        }
        `}
        render={data => 
            <Layout location={location}>
               <SEO
                  keywords={[`virtualmoon`, `vr`, `moon`, `space`]}
                  title="Lunar Explorer"
                />
              <section id="intro" className="text-left pt-24 sm:ml-5 md:pl-20 md:ml-32">
              <h1 className="text-white font-title font-thin text-4xl mb-5">{data.wpgraphql.page.title}</h1>
              <div className="text-white font-body md:w-5/6 sm:w-full">{ ReactHtmlParser(data.wpgraphql.page.content) }</div>;
              <div>
            </div>
            </section>
            </Layout>
          }
      />
    )
}

LunarExplorer.propTypes = {
  location: PropTypes.object
};

export default LunarExplorer;
